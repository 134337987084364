import React, { Component } from 'react';
import { WalletFollower } from '@funfair-tech/wallet-react';
import styled from 'styled-components';
import BackgroundImg from '../assets/bg-cashier.jpg';
import ButtonImg from '../assets/btn-cashier.svg'
import LogoImg from '../assets/logo-cashier.png';
import Logout from './components/Logout';
import { login } from './services/wallet-service';
import { isAuthenticated$, restoreAuthenticationTaskCompleted$ } from './services/store';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoggedIn: false, loading: true };
    }
  
    componentDidMount() {
        isAuthenticated$.subscribe((value) => {
            this.setState({
                isLoggedIn: value,
                loading: !restoreAuthenticationTaskCompleted$.value,
            });
            this.forceUpdate();
        });
  
        restoreAuthenticationTaskCompleted$.subscribe((value) => {
            if (value) {
                this.setState({ isLoggedIn: isAuthenticated$.value, loading: false });
                this.forceUpdate();
            }
        });
    }
  
    render() {
        return (
            <AppContainer>
                { !this.state.isLoggedIn && (
                    <Background>
                        <Container>
                            <Logo></Logo>
                            <P>Wallet provided by FunFair Technologies</P>
                            <LoginButton onClick={login}>Login / Sign Up</LoginButton>
                        </Container>
                    </Background>
                )}

                <div className="action-buttons">
                    {this.state.loading ? <p>Loading please wait</p> : null}
                    {this.state.isLoggedIn && !this.state.loading ? (
                        <Logout />
                    ) : null}
                </div>
                {this.state.isLoggedIn ? 
                    <WalletFollowerContainer>
                        <WalletFollower page={window.funwallet.deepLinkRouteTypes.accountSettings} />
                    </WalletFollowerContainer> 
                    : null}
            </AppContainer>
        );
    }
}

const WalletFollowerContainer = styled.div`
    width: 100%;
`;

const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -150px 0 0 -150px;
    /* transform: translateY(-50%); */
`;

const P = styled.p`
    font-size: 14px;
    font-family:"Helvetica","Arial",sans-serif;
    color: #8387a3;
    letter-spacing: 0;
    line-height: 14px;
    font-weight: 400;
    width: 100%;
    text-align: center;
    margin: auto;
    margin-bottom: 35px;
`;

const AppContainer = styled.div`
    /* height: 1050px; */
    background-color: #20212c;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
`;

const Background = styled.div`
    background-image: url(${BackgroundImg});
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    width: 100%;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
`;

const Logo = styled.div`
    background-image: url(${LogoImg});
    width: 215px;
    height: 137px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: auto;
    margin-bottom: 35px;
`;

const LoginButton = styled.div`
    background-image: url(${ButtonImg});
    color: white;
    width: 300px;
    height: 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: auto;
    text-align: center;
    vertical-align: middle;
    line-height: 66px;
    cursor: pointer;
    user-select: none;
    font-size: 14px;
    font-family:"Arial",sans-serif;
    font-weight: bold;

    &:hover {
        opacity: 0.9;
    }
    &:focus {
        opacity: 0.8;
    }
    &:active {
        opacity: 0.8;
    }
`;

export default App;
