import React, { Component } from 'react';
import styled from 'styled-components';
import LogoutImg from '../../assets/ico-logout.svg';
import { logout } from '../services/wallet-service';

class Logout extends Component {
    render() {
        return (
            <LogoutButton onClick={logout}>
                <LogoutIcon />Logout
            </LogoutButton>
        );
    }
}

const LogoutButton = styled.button`
    font-size: 14px;
    font-family: "Arial",sans-serif;
    background-color: #20212c;
    color:  #8387A3;
    border: 0;
    float: right;
    cursor: pointer;
    user-select: none;
    margin-right: 15px;
    margin-top: 10px;

    &:focus {
        outline: none;
    }
`;

const LogoutIcon = styled.img.attrs({
    src: LogoutImg,
})`
  margin-right: 5px;
  vertical-align: text-top;
`;

export default Logout;
